import React from 'react';
import Session from '../classes/Session.js';

export default class ViewerJoin extends React.Component {
  
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <div className="join">
        <h1>Viewer</h1>
        <h2>Join Presentation</h2>
        <button onClick={this.handleSubmit}>Join</button>
      </div>
    )
  }

  handleSubmit() {
    Session.id = 'TEST';
  }
}