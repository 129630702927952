/*global AFRAME */
import Session from './Session';
import Comms from './Comms';

class Camera {

  constructor() {

    window.addEventListener( 'keypress', (evt) => {
      if (evt.code==='KeyS') {
        this.setRotation();
      }
    });

    AFRAME.registerComponent( 'camera-commands', {
      init: function() {

        const camera = this.el;

        Comms.addListener( (event, params) => {

          console.log( '>>> AFRAME camera-commands', event, params );

          switch (event) {

            case 'camera.set-rotation': 

              if (!Session.isPresenter) {

                const sceneEl = this.el.sceneEl;
                const isVR = sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected();
                const cameraRotation = document.getElementById( 'camera-rotation' );
                const lookControls = camera.components[ 'look-controls' ];

                if (isVR) {
                  cameraRotation.object3D.rotation.y = params.y- this.el.object3D.rotation.y;
                } else {
                  lookControls.pitchObject.rotation.x = params.pitch;              
                  lookControls.yawObject.rotation.y = params.yaw;
                  lookControls.pitchObject.rotation.z = 0;
                }

              }
              break;

            default:
              break;
          }
        });

      }
    });

  }

  setRotation() {
    if (Session.isPresenter) {
      const camera = document.getElementById( 'camera' );
      const lookControls = camera.components[ 'look-controls' ];
      Comms.broadcast( 'camera.set-rotation', {
        pitch: lookControls.pitchObject.rotation.x,
        yaw: lookControls.yawObject.rotation.y,
        y: camera.object3D.rotation.y
      });
    }
  }

  goHome( yaw = 0 ) {
    if (Session.isPresenter) {  
      const camera = document.getElementById( 'camera' );
      const lookControls = camera.components[ 'look-controls' ];

      lookControls.pitchObject.rotation.x = 0;              
      lookControls.yawObject.rotation.y = 0;
      lookControls.pitchObject.rotation.z = 0;

      this.setRotation();
    }
  }

  // Performs "Ken Burns" style zoom whereby the scene is zoomed very very slowly. Note, the zoom
  // is achieved by changing the camera FOV (for simplicity)
  zoom( flag ) {

  }
}

export default new Camera();