import React from 'react';
import State from '../classes/State';
import Camera from '../classes/Camera';
import Highlight from '../classes/Highlight';

import './VR.scss';

export default class VR extends React.Component {

  constructor(props) {
    super(props);
    this.camera = Camera;
    this.highlight = Highlight;
    this.setSkyPosition = true;
    this.onStateEvent = this.onStateEvent.bind(this);
  }

  componentDidMount() {
    State.addListener( this.onStateEvent );
  }

  componentWillUnmount() {
    State.removeListener( this.onStateEvent );
  }

  onStateEvent( event, data ) {
    switch (event) {
      case 'loaded':
      case 'activeSceneIdChanged':
        this.setSkyPosition = true;
        this.forceUpdate();
        break;
      default:
        break;
    }
  }

  render() {

    const assetList = this.props.presentation.scenes.map( scene => 
      <img crossOrigin="anonymous" id={scene._uid} src={scene.image.filename} alt="scene" key={scene._uid}/>
    )

    let sky_position = 0;
    let sky_id = '';

    const showHolding = !State.activeSceneId;

    if (showHolding) {
      const hasHolding = this.props.presentation.holding_image && this.props.presentation.holding_image.filename;
      sky_id = hasHolding ? '__holding__' : '';
      sky_position = hasHolding ? parseInt( this.props.presentation.holding_position ) || 0 : 0;
    } else {
      sky_id = State.activeSceneId;
      sky_position = 0;
    }

    const holding_url = this.props.presentation.holding_image && this.props.presentation.holding_image.filename ?
      this.props.presentation.holding_image.filename : '';

    assetList.push(
      <img crossOrigin="anonymous" id="__holding__" src={holding_url} alt="scene" key="__holding__"/>
    );
    const rotation = `0 ${sky_position} 0`;

    const classnames = this.props.presenter ? 'VR is-presenter' : 'VR';

    return (
      <div className={classnames}>

        <a-scene loading-screen="dotsColor: white; backgroundColor: black" {...( this.props.presenter && { embedded: true, 'vr-mode-ui': 'enabled:false' } )} id="scene" inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js">
        
          <a-assets id="assets">{assetList}</a-assets>

          <a-sky id="sky" src={'#'+sky_id} rotation ={rotation}></a-sky>

          <a-entity 
            id="highlight" 
            geometry="primitive: circle;"
            material="color: yellow; shader: flat; opacity:0.25;"
            scale="1 1 1"
            position="0 0 -10"
            rotation="0 0 90"
            billboard
            highlight
          ></a-entity>
      
          <a-entity rotation="0 0 0" id="camera-rotation">
            <a-camera id="camera" position="0 0 0" camera-commands>  
              <a-entity
                id="cursor"
                raycaster="objects: .clickable"
                cursor="fuse: true; fuseTimeout:1500"
                material="color: white; shader: flat;"
                position="0 0 -3"
                rotation="0 0 90"
                geometry="primitive: ring; thetaLength:360"
                scale="0.05 0.05 0.05"
                animation__fusing_ring="property: geometry.thetaLength; startEvents: fusing; dur: 1500; to: 0;"  
                animation__mouseleave_ring="startEvents: mouseleave; property: geometry.thetaLength; dur: 100; to: 360;"
              ></a-entity>
            </a-camera>
          </a-entity>
        </a-scene>
      </div>
    );
    
  }
}
