import React from 'react';
import Viewer from './Viewer';
import ViewerJoin from './ViewerJoin';
import Presenter from './Presenter';
import PresenterStart from './PresenterStart';
//import Environment from '../classes/Environment';
import Session from '../classes/Session';
import Comms from '../classes/Comms';

export default class App extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      connected: false
    }

    this.onCommsEvent = this.onCommsEvent.bind(this);
  }

  componentDidMount() {
    Comms.addListener( this.onCommsEvent );

    // If we have an id in the url, then attempt to connect
    if (Session.id) {
      Comms.connect();
    }

  }

  componentWillUnmount() {
    Comms.removeListener( this.onCommsEvent );
  }

  onCommsEvent( event, data ) {

    switch (event) {
      
      case 'connected': 
        // When we connect to the websocket, we then tell it we want to join the session.
        // The websocket will send back a "joined" message (see below)
        Comms.send( 'join', {} ); 
        break;

      case 'joined': {

        // We joined a session..the websocket will contain the presentation data and current state (if rejoining)
        Session.presentation = data.presentationData;
        //Session.activeSceneId = Session.activeSceneId || Session.presentation.scenes[0]._uid;
        this.setState( { connected: true });
        break;
      }

      default:
        break;

    }

  }

  render() {
    // If we have a session ID then are we also connected?
    if (Session.id && !this.state.connected) {
      return <h1>Loading...</h1>
    } else {
      return Session.id ? Session.isPresenter ? (<Presenter></Presenter>) : (<Viewer></Viewer>) : 
        Session.isPresenter ? (<PresenterStart></PresenterStart>) : (<ViewerJoin></ViewerJoin>);
    }
  }

}
