/*global AFRAME, THREE */
import Comms from './Comms';
import State from './State';

class Highlight {

  constructor() {

    window.addEventListener( 'keypress', (evt) => {
      if (evt.code==='KeyH') {
        this.setToCamera();
      }
    });

    AFRAME.registerComponent( 'highlight', {

      init: function() {

        this.el.setAttribute( 'visible', false );

        State.addListener( (event) => {
          if (event==='activeSceneIdChanged' || event==='loaded') {
            this.el.setAttribute( 'visible', false);
          }
        });

        Comms.addListener( (event, params) => {

          console.log( '>>> AFRAME highlight', event, params );

          switch (event) {

            case 'highlight.set-position': 

              const hide = (params.x===params.y===params.z===0);
              const scene = this.el.sceneEl;
              const camera = document.getElementById( 'camera' );
console.log( camera.object3D.fov );
              if (hide) {

                camera.setAttribute( 'animation__zoom', '' );
                camera.setAttribute( 'fov', '80' );
                
              } else {

                // Do a Ken Burns on the Camera!
   //             camera.setAttribute( 'animation__zoom', 'property:camera.fov; from:80; to:70; dur:15000; easing:linear' );

                // When used on a headset, the highlight y-coord appears slightoff! So we need
                // to adjust if in a headset
                const isVR = scene.is('vr-mode') && scene.checkHeadsetConnected();

                params.y += isVR ? 1.5 : 0

                this.el.setAttribute("position", params);
              }

              this.el.setAttribute( 'visible', !hide );

              break;

            default:
              break;
          }
        });

      }
    });

  }

  hide() {
    Comms.broadcast( 'highlight.set-position', { x:0, y: 0, z: 0 } );
  }

  setToCamera() {

    const direction = new THREE.Vector3();
    const camera = document.getElementById( 'camera' );
    //const highlight = document.getElementById( 'highlight' );

    camera.object3D.getWorldDirection( direction );

    direction.multiplyScalar(-10);

    // faster than the below code - but getAttribute wont work
    // player.object3D.position.add(direction)
    //var pos = highlight.getAttribute("position")

    const x = direction.x
    const y = direction.y; // comment this to get 2D movement
    const z = direction.z
    
    Comms.broadcast( 'highlight.set-position', { x, y, z } );

  }

}

export default new Highlight();