import React from 'react';

import './Header.scss';
import PublicURL from './PublicURL';
import Session from '../classes/Session';

export default class Header extends React.Component {
  render() {

    const name = Session.presentation ? Session.presentation.title : '';

    return (
      <div className="Header">
        <img className="imagination" src="assets/imagination.png" alt="imagination logo" />
        <img className="meetVR" src="assets/meetvr.svg" alt="meetVR logo" />
        <div className="Header__presentation">
          <label>Presenting "<span className="name">{name}</span>"</label>
          <PublicURL></PublicURL>
        </div>
      </div>
    );
  }
}
