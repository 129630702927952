import React from 'react';
import VR from './VR';
import Session from '../classes/Session';

export default class Viewer extends React.Component {
  render() {
    return (
      <VR presenter={false} presentation={Session.presentation}></VR>
    )
  }

}