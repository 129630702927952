import Config from './Config';
import Session from './Session';
import Listener from './Listener';

class Comms extends Listener {

  constructor() {
    super();
    this.socket = null;
  }

  get connectionString() {
    return `${Config.ws}?id=${Session.id}&alias=${Session.alias}`
  }

  connect() {
    return new Promise( (resolve, reject) => {

      if (!Session.id) {
        return reject( new Error( 'No Session id specified!') );
      }

      // Remove current connection (if there is one)
      this.disconnect();

      // Create new connection
      this.socket = new WebSocket( this.connectionString );

      // Connection opened
      this.socket.addEventListener( 'open', (event) => {
        this.emit( 'connected' );
      });

      // Connection message
      this.socket.addEventListener( 'message', (event) => {
        try {
          const msg = JSON.parse( event.data );
          this.emit( msg.data.event, msg.data.data );
        } catch (e) { /* Not valid JSON! */}
      });

      // Connection closed
      this.socket.addEventListener( 'close', (event) => {
        this.emit( 'close' );

        // Try to re-open
        setTimeout( () => { this.connect() }, 1000 );

      });

      // Connection Error
      this.socket.addEventListener( 'error', (event) => {
        this.emit( 'error', event.data );
      });
  
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.emit( 'disconnected' );
    }
  }

  broadcast( event, data ) {
    this.socket.send( JSON.stringify({
      action: 'broadcast',
      data: {
        event, 
        data
      }
    }));
  }

  send( action, data ) {
    this.socket.send( JSON.stringify( { action, data } ) );
  }

}

export default new Comms();