import Listener from './Listener';
import Comms from './Comms';
import Session from './Session';

const MESSAGE_ACTIVE_SCENE_ID_CHANGED = 'activeSceneIdChanged';
const MESSAGE_STATE_ACTION = 'state-action';

class State extends Listener {

  _state = {};

  constructor() {
    super();
    
    // Listen for communications
    this.onCommsEvent = this.onCommsEvent.bind( this );
    Comms.addListener( this.onCommsEvent );

  }

  onCommsEvent( event, payload ) {
    
    console.log( '>>> State.onCommsEvent', event, payload );
    
    switch (event) {

      case 'joined':
        this._state.activeSceneId = payload.state.activeSceneId;
        this.emit( 'loaded' );
        break;

      case 'state.' + MESSAGE_ACTIVE_SCENE_ID_CHANGED: 
        this.activeSceneId = payload.sceneId;
        break;

      default:
        break;
    }
  }

  get activeSceneId() {
    return this._state.activeSceneId || '';
  }

  set activeSceneId( value ) {
    const changed = this.activeSceneId !== value;
    if (changed) {
      this._state.activeSceneId = value;

      this.emit( MESSAGE_ACTIVE_SCENE_ID_CHANGED, value );

      if (Session.isPresenter) {
        Comms.send( MESSAGE_STATE_ACTION, {
          event: MESSAGE_ACTIVE_SCENE_ID_CHANGED,
          data: { sceneId: value } 
        });
      }
    }
  }

  get activeScene() {
    return (Session.presentation && this.activeSceneId) ?
      Session.presentation.scenes.find( scene => scene._uid === this.activeSceneId ) : null;
  }

}

export default new State();